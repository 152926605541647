
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
@tailwind base;
@tailwind components;
@tailwind utilities;

.icon path {
  @apply fill-transparent;
}
