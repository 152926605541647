
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-multi-select-list {
  &__selected-list {
    border-top: 1px dashed rgba($color-neutral-one, 0.25);
    font-weight: $font-weight-semibold;
    font-size: 16px;
    margin-bottom: 30px;
  }

  &__selected-item {
    border-bottom: 1px dashed rgba($color-neutral-one, 0.25);
    background-color: $color-neutral-three;
    padding: 0 10px;
    display: flex;
  }

  &__selected-item-name {
    width: 100%;
    padding: 13px 10px 13px 0;
    word-break: break-word;
  }

  &__selected-item-action {
    min-width: 50px;
    height: 50px;
    cursor: pointer;
    position: relative;

    &.disable {
      cursor: default;

      &::before {
        color: $color-neutral-two;
      }

      &:hover {
        &::before {
          color: $color-neutral-two;
        }
      }
    }

    &::before {
      color: $color-brand-one;
      font-size: 37px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      width: 50px;
      position: absolute;
    }

    &:hover {
      &::before {
        color: $color-brand-two;
      }
    }

    &:active,
    &:focus {
      &::before {
        color: $color-brand-two;
      }
    }

    &:focus {
      outline: 2px solid $blueFocus;
      border-radius: 4px;
    }
  }
}
