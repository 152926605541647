
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.feature-box {
  @apply px-2 pb-2 rounded-lg bg-color-neutral-three;

  &--selected {
    @apply bg-blue-sky-2;
  }

  &__inner {
    @apply flex justify-between items-center md:gap-1;

    &--mobile {
      @apply flex-col items-stretch;
    }

    &__container {
      @apply relative flex flex-col gap-1 w-full;
    }

    &__title {
      @apply text-2xl font-semibold uppercase;

      &--mobile {
        @apply pt-1;
      }
    }

    &__price-block {
      @apply flex items-center gap-3 p-1 items-center;

      &--mobile {
        @apply justify-between flex-wrap;
      }
    }

    &__price-info {
      @apply text-center;

      &--mobile {
        @apply flex flex-col items-center gap-1;
      }
    }

    &__price-button {
      @apply min-w-[100px];

      &--mobile {
        @apply flex-[0];
      }
    }

    &__features-list {
      @apply flex flex-wrap gap-1;
    }

    &__features-sublist {
      @apply mt-0.5 text-lg font-semibold;
    }

    &__feature {
      @apply flex items-start justify-between w-full p-3 bg-white rounded-md;

      &--mobile {
        @apply relative;
      }
    }

    &__feature-text-block {
      @apply flex gap-2 w-full;
    }

    &__feature-icon {
      @apply w-6 h-6 mt-0.5;
    }

    &__feature-additional-icon {
      @apply absolute right-1 top-1;

      &--mobile {
        @apply self-start static;
      }
    }

    &__feature-title {
      @apply text-xl font-semibold;
    }
  }
}
