
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.ql-editor {
  font-size: 16px;
  font-family: inherit;
  height: 160px;
}

.ql-tooltip.ql-editing {
  left: 0 !important;
}

.ql-container.ql-snow, .ql-toolbar.ql-snow {
  @apply border border-color-neutral-two;
}

.rich-text {
  &__wrapper {
    margin-bottom: 32px;

    .ql-container {
      @apply border-b-0 font-primary;
    }

    &--invalid {
      & > .quill > .ql-container.ql-snow,
      & > .quill > .ql-toolbar.ql-snow,
      & > .rich-text__word-count-below {
        border-color: $cardinal-red;
      }
    }

    &--white {
      .quill, .ql-editor, .rich-text__word-count-below {
        @apply bg-white;
      }
    }

    .ql-editor.ql-blank::before {
      @apply not-italic;
    }
  }

  &__word-count-below {
    display: block;
    position: static;
    width: 100%;
    min-height: 29px;
    border: 1px solid $color-neutral-two;
    border-top: 0;
    padding-left: 5px;
    padding-bottom: 1px;

    :focus-within {
      @apply bg-white;
    }
  }
}
