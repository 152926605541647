
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-alert-message {
  text-align: left;

  &__header {
    border-bottom: 1px solid $medium-grey-3;
    padding: 0 13px;
    display: flex;
    align-items: center;
    height: 73px;
    margin-bottom: 13px;
  }

  &__sign {
    min-width: 10px;
    height: 45px;
    margin: -4px;
    flex-shrink: 0;
    background-size: 100%;

    & svg {
      width: 45px;
      height: 45px;
    }

    & + .c-alert-message__title {
      margin-left: 15px;
      margin-top: 2px;
    }
  }

  &__title {
    color: $color-neutral-one;
    line-height: 33px;
    font-size: $font-size-large;
    font-weight: $font-weight-regular;
  }

  &__description {
    padding: 12px 15px;

    & ul {
      padding-left: 20px;
    }
  }

  &__additional-content {
    @apply px-4 py-2;
  }

  &__buttons {
    margin-top: 22px;
    padding: 0 17px 0 20px;
  }

  &__button {
    margin-top: 10px;

    &:first-child &:has(.g-inner-grid) {
      margin-top: 0;
    }
  }

  &__buttons-right {
    @include breakpoint(medium_up) {
      display: flex;
      gap: 1rem;
      justify-content: flex-end;
      align-items: center;
      margin-top: 0;

      * {
        text-align: center;
      }
    }
  }
}
